import { Typography } from "@mui/material"
import React from "react"
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined"

const UnderConstruction = () => {
    return (
        <>
            <EngineeringOutlinedIcon color="primary" />
            <Typography variant="h4">Эта страница находится в разработке</Typography>
            <Typography variant="h5">Мы работаем над этим...</Typography>
        </>
    )
}

export default UnderConstruction
