import React from "react"
import UnderConstruction from "../components/UnderConstruction"
//import img1 from "../assets/images/img1.png"
import { Box, Typography } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export const allImagesQuery = graphql`
    query allImagesQuery {
        photos: allFile(sort: { fields: base, order: ASC }, filter: { extension: { regex: "/(png)/" } }) {
            edges {
                node {
                    id
                    base
                    childImageSharp {
                        gatsbyImageData(formats: AUTO)
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`

const Faq = props => {
    return (
        <>
            <Typography variant="h4">Работа в ЛК</Typography>
            <Typography>В ЛК есть основные разделы: Запросы, Заказы, Счета, Отгрузки</Typography>
            <Box key={props.data.photos.edges[0].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[0].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[0].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[0].node)}
                />
            </Box>
            <Typography variant="h4">Раздел Запросы</Typography>
            <Typography>
                Состоит из подразделов: Новый запрос, Загрузить запрос, Запросы в работе, Архив запросов
            </Typography>

            <Typography variant="h5">Подраздел Новый запрос</Typography>
            <Typography>
                Актуален при выборе нужной номенклатуры на складе. Поиск осуществляется через верхнюю строку поиска.
                Можно искать по: p/n, наименованию клиента (будет работать при внесении в базу поставщика наименования
                клиента), производителю{" "}
            </Typography>
            <Box key={props.data.photos.edges[1].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[1].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[1].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[1].node)}
                />
            </Box>
            <Typography>
                При выборе в ЛК нужной позиции и отправке ее в запрос, появляется значок, сообщающий кол-во
                номенклатуры, добавленное в запрос
            </Typography>
            <Box key={props.data.photos.edges[2].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[2].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[2].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[2].node)}
                />
            </Box>
            <Typography>
                Далее, переходим в раздел Запросы, и видим выбранную позицию в подразделе Новый запрос, где можно ее
                удалить или внести нужное кол-во. После чего отправляем запрос. (Далее см. Подраздел Запросы в работе)
            </Typography>
            <Box key={props.data.photos.edges[3].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[3].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[3].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[3].node)}
                />
            </Box>

            <Typography variant="h5">Подраздел Загрузить запросы</Typography>
            <Typography>
                Можно загрузить через Шаблон или через выбранный файл. Должны соблюдаться требования, как в шаблоне.
                Указывается p/n и кол-во в штуках. Также, можно вместо p/n вносить свое название. Наш менеджер получит
                запрос и, при необходимости, состыкует наименования клиента с наименованием в базе поставщика
            </Typography>
            <Box key={props.data.photos.edges[4].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[4].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[4].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[4].node)}
                />
            </Box>

            <Typography variant="h5">Подраздел Запросы в работе</Typography>
            <Typography>
                После создания запроса, он попадает в данный раздел. Дальше информацию вносит менеджер нашей компании,
                где указывает цены, сроки, меняет статус запроса.
            </Typography>
            <Box key={props.data.photos.edges[5].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[5].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[5].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[5].node)}
                />
            </Box>
            <Typography>
                Если предложение по позиции актуально для заказа, выбираем нужную позицию и добавляем в заказ
            </Typography>

            <Typography variant="h5">Подраздел Архив запросов</Typography>
            <Typography>
                Для удобства, все неактуальные запросы можно перенести в архив запросов. Это делает покупатель.
                Убирается в архив запрос целиком, а не построчно!
            </Typography>

            <Typography variant="h4">Раздел Заказы</Typography>

            <Typography variant="h5">Подраздел Новый заказ</Typography>
            <Typography>
                Информация в данный подраздел попадает из подраздела запросы в работе. Выбрав нужную позицию (Статус у
                позиции должен быть «Обработан») отправляем ее через кнопку «Добавить в заказ», после чего над разделом
                «Заказы» появляется цифра в красном кружке, сообщающая о кол-ве позиций, добавленных в подраздел «Новые
                заказы».
            </Typography>
            <Box key={props.data.photos.edges[6].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[6].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[6].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[6].node)}
                />
            </Box>
            <Typography>
                Перейдя в подраздел «Новые заказы» можно в табличной части изменить количество или удалить позицию из
                таблицы. Далее формируется заказ
            </Typography>
            <Box key={props.data.photos.edges[7].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[7].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[7].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[7].node)}
                />
            </Box>

            <Typography variant="h5">Подраздел Согласованные заказы</Typography>
            <Typography>
                В данном разделе указаны все заказы согласованные с покупателем. В графах указаны данные по названию,
                количеству, цене и т.д. В графе размещение указан статус:
            </Typography>
            <Typography>
                В резерве – в наличии у поставщика с указанием даты отгрузки покупателю Заказано – Размещено поставщиком
                с указанием даты отгрузки покупателю В процессе размещения – размещается поставщиком для поставки с
                указанием даты отгрузки покупателю
            </Typography>
            <Box key={props.data.photos.edges[8].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[8].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[8].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[8].node)}
                />
            </Box>
            <Typography>
                После формирования заказа статус у позиций «В процессе размещения». После размещения заказа меняется
                статус и появляется ориентировочная дата поставки
            </Typography>
            <Box key={props.data.photos.edges[9].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[9].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[9].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[9].node)}
                />
            </Box>

            <Typography variant="h5">Подраздел Архив заказов</Typography>
            <Typography>
                Для переноса заказа в архив можно в разделе Согласованные заказы использовать для этого кнопку переноса
                в архив
            </Typography>
            <Box key={props.data.photos.edges[10].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[10].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[10].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[10].node)}
                />
            </Box>

            <Typography variant="h4">Раздел Счета</Typography>

            <Typography variant="h5">Подраздел Новый счет</Typography>
            <Typography>
                Информация в данный подраздел попадает из подраздела Согласованные заказы. Выбрав нужную позицию
                отправляем ее через кнопку «Добавить в счет», после чего над разделом «Счета» появляется цифра в красном
                кружке, сообщающая о кол-ве позиций, добавленных в подраздел Новый счет
            </Typography>
            <Box key={props.data.photos.edges[11].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[11].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[11].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[11].node)}
                />
            </Box>

            <Typography variant="h5">Подраздел Счета в работе</Typography>
            <Typography>
                Перейдя в подраздел «Новый счет» можно в табличной части изменить количество или удалить позицию из
                таблицы. Далее формируется счет
            </Typography>
            <Box key={props.data.photos.edges[12].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[12].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[12].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[12].node)}
                />
            </Box>

            <Typography variant="h5">Подраздел Архив счетов</Typography>
            <Typography>
                Документ перемещается в архив при наличии статуса «Оплачен», если с даты документа прошло более месяца
            </Typography>

            <Typography variant="h4">Раздел Отгрузки</Typography>

            <Typography variant="h5">Подраздел Отгрузки в работе</Typography>
            <Typography>
                Все документы по отгрузкам создает менеджер поставщика. Сделано для просмотра и скачивания.
            </Typography>

            <Typography variant="h5">Подраздел Архив отгрузок</Typography>
            <Typography>
                Документ перемещается в архив при наличии статуса «Закрыт», если с даты документа прошло более месяца
            </Typography>

            <Typography variant="h4">Дополнительные материалы:</Typography>

            <Typography>
                Всю информацию из всех разделов можно скачать в формате excel. Просто зайдите в раздел и нажмите на
                значок.
            </Typography>
            <Box key={props.data.photos.edges[13].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[13].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[13].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[13].node)}
                />
            </Box>
            <Typography>
                Для удобства, поиск компонента в каталоге лучше осуществлять по общему названию компонента, чтобы
                увидеть предложение по разным производителям.
            </Typography>
            <Box key={props.data.photos.edges[14].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[14].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[14].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[14].node)}
                />
            </Box>
            <Typography>
                По части компонентов в каталоге можно увидеть аналоги (по мнению поставщика). При наличии аналога рядом
                с номиналом появляется активный значок, с помощью которого раскрывается окно с аналогами
            </Typography>
            <Box key={props.data.photos.edges[15].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[15].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[15].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[15].node)}
                />
            </Box>
            <Typography>В разделах Запросы, Заказы, Счета можно делать группировку по документам</Typography>
            <Box key={props.data.photos.edges[16].node.id} sx={{ mt: "1rem", mb: "1rem" }}>
                <GatsbyImage
                    fluid={props.data.photos.edges[16].node.childImageSharp.fluid}
                    alt={props.data.photos.edges[16].node.base.split("-").join(" ").split(".")[0]}
                    image={getImage(props.data.photos.edges[16].node)}
                />
            </Box>
        </>
    )
}

export default Faq
